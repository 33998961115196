<template>
  <Auth
    class="custom-inputs"
    title="Set New Password"
    description="Enter and confirm your new password in the fields"
    header-logo="set-password"
  >
    <ValidationObserver @submit="passwordReset">
      <InputFieldWrapper>
        <InputBox
          v-model.number="otp"
          type="text"
          pattern="[0-9]+"
          :disabled="!!otp"
          title="OTP"
          name="OTP"
          placeholder="Enter OTP"
          class="border-none"
          rules="required"
        />
      </InputFieldWrapper>
      <InputFieldWrapper>
        <InputBox
          v-model="password"
          title="Password"
          name="Password"
          type="password"
          placeholder="Password"
          vid="confirmation"
          class="border-none"
          autocomplete="new-password"
          rules="min:8|required"
        />
      </InputFieldWrapper>
      <InputFieldWrapper>
        <InputBox
          v-model="confirmPassword"
          v-model:confirm-password="password"
          type="password"
          title="CONF_PASS"
          name="CONF_PASS"
          placeholder="Confirm Password"
          class="border-none"
          autocomplete="new-password"
          rules="required|confirmed:@Password"
        />
      </InputFieldWrapper>
      <UIButton button="primary" :disabled="isLoading" class="w-full mt-1">
        <span v-if="isLoading">
          <Loader />
        </span>
        <span v-else>Reset Password</span>
      </UIButton>
    </ValidationObserver>
  </Auth>
</template>

<script>
import Auth from '@layouts/auth.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { mapActions } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'

export default {
  components: {
    Auth,
    InputBox,
    Loader,
    ValidationObserver,
    UIButton,
    InputFieldWrapper,
  },
  data() {
    return {
      isLoading: false,
      password: '',
      confirmPassword: '',
      otp: '',
      email: '',
      subDomain: '',
    }
  },
  mounted() {
    this.OTPHandler()
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    passwordReset() {
      this.isLoading = true
      let payload = {
        password: this.password,
        otp: this.otp,
        email: this.email,
        sub_domain: this.subDomain,
      }
      this.resetPassword(payload)
        .then(() => {
          this.isLoading = false
          this.$router?.push({ name: 'login' })
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    OTPHandler() {
      let otp = this.$route.query.otp
      let email = this.$route.query.email
      let subDomain = this.$route.query.sub_domain
      if (otp) this.otp = otp
      if (email) this.email = email
      if (subDomain) this.subDomain = subDomain
    },
  },
}
</script>

<style lang="scss" module>
@import '@design';
</style>

<style lang="scss">
@import '@src/router/views/auth/auth.scss';
</style>
